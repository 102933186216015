import { PermissionConfig, PermissionEnumType, PermissionType, PermissionTypeMetadata } from '../models';
import { metadataSymbol, typeSymbol, valueSymbol } from '../symbols';

/**
 * Uses a permission enum and creates the permission values that must be used throughout the application to
 * perform permission checks.
 * @param permissions The permission enum (must correspond to an equal enum on the backend side).
 * @param config The config of the permissions.
 * @returns The permissions to use.
 */
export function permissions<TEnum extends PermissionEnumType<TEnum>>(
  permissions: TEnum,
  config: PermissionConfig<TEnum>,
): PermissionType<TEnum> {
  // Create a symbol for the type
  const symbol = Symbol(`Permission ${config.featureId}`);

  // Create the metadata
  const metadata = Object.freeze<PermissionTypeMetadata<TEnum>>({
    config,
  });

  // Create the result
  const o = Object.create({
    [typeSymbol]: symbol,
    [metadataSymbol]: metadata,
  });

  // Enumerate the permissions and create the values
  for (const property of Object.getOwnPropertyNames(permissions)) {
    const prop = Object.getOwnPropertyDescriptor(permissions, property);

    // Use all values of type number that begin with a capital letter as per convention.
    if (typeof prop?.value === 'number' && property.charAt(0).toUpperCase() === property.charAt(0)) {
      const value = Object.create({
        [typeSymbol]: symbol,
        [valueSymbol]: prop.value,
        valueOf() {
          return prop.value;
        },
        toString() {
          return `${prop.value}`;
        },
        toJSON() {
          return prop.value;
        },
      });

      Object.defineProperty(o, property, {
        enumerable: true,
        value: Object.freeze(value),
      });
    }
  }

  return Object.freeze(o);
}
